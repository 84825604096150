<template>
    <div class="dirr mt-5">


        <!-- <div class="muhram" >
                <div class="mt-4">
                    <img :src="'/assets/img/logo6.svg'" height="56px">
                    <h2 class="text-white mt-6">  {{ $t('Dear_visitors_services_page_in_holy_month_Muharram') }}


                </h2>
            </div>

            <div class="i-muhram">
                <router-link :to="'/muharram/?lang='+$i18n.locale+'#khdham'" class="m-link text-center">

                    <div class="ico mx-auto">
                        <img class="mx-auto" :src="'/assets/img/moharam/muslim.png'" style="height: 48px !important;">
                    </div>
                    <h4 class="ico-text text-white text-center"> {{ $t('Servants_Hussein') }}</h4>
                </router-link>
                <router-link :to="'/muharram/?lang='+$i18n.locale+'#enabaa'" class="m-link">
                    <div class="ico2 mx-auto"><svg viewBox="0 0 24 24" style="max-height: 52px;">
                            <use xlink:href="/assets/img/moharam/enabaa2.svg#view"></use>
                        </svg></div>
                    <h4 class="ico-text text-white text-center">{{ $t('index_zyara') }}</h4>
                </router-link>
                <router-link :to="'/muharram/?lang='+$i18n.locale+'#live'" class="m-link">
                    <div class="ico2 mx-auto"><svg viewBox="3 2 23 24" style="max-height: 50px;">
                            <use xlink:href="/assets/img/moharam/live2.svg#view"></use>
                        </svg></div>
                    <h4 class="ico-text text-white text-center">{{ $t('index_live') }}</h4>
                </router-link>
                <router-link :to="'/muharram/?lang='+$i18n.locale+'#events'" class="m-link" v-if="$i18n.locale=='ar'">
                    <div class="ico mx-auto"><svg viewBox="1 0 23 24" style="max-height: 50px;">
                            <use xlink:href="/assets/img/moharam/calendar2.svg#view"></use>
                        </svg></div>
                    <h4 class="ico-text text-white text-center">{{ $t('today_events') }}</h4>
                </router-link>
                <router-link :to="'/muharram/?lang='+$i18n.locale+'#visitor'" class="m-link">
                    <div class="ico2 mx-auto">
                        <svg viewBox="0 0 25 25" width="45">
                            <use xlink:href="/assets/img/services/location.svg#view"></use>
                        </svg>
                    </div>
                    <h4 class="ico-text text-white text-center">{{ $t('visitor_guide') }}</h4>
                </router-link>

                <router-link :to="'/muharram/?lang='+$i18n.locale+'#ashab'" class="m-link">
                    <div class="ico mx-auto">
                        <img class="mx-auto" :src="'/assets/img/moharam/ashab.svg'" style="height: 48px !important;">
                    </div>
                    <h4 class="ico-text text-white text-center">{{ $t('CompanionsـHussein') }}</h4>
                </router-link>
                <router-link to="/muharram/#stats" class="m-link"  v-if="$i18n.locale=='ar'">
                    <div class="ico mx-auto"><svg viewBox="1 0 23 24" style="max-height: 50px;"><use  xlink:href="/assets/img/moharam/clipboard2.svg#view"></use></svg></div>
                    <h4 class="ico-text text-white text-center">{{ $t('muharram_stats') }}</h4>
                </router-link> 
            </div>
            
        </div> -->
        <v-container style="max-width:1261px !important">


        <template v-if="timestamp < 1736985600">
            <v-responsive class="d-none d-sm-flex">
                <a :href="'https://alkafeel.net/news/index?id=28822&lang='+$i18n.locale" target="_blank">
                    <v-img v-if="$i18n.locale == 'ar' || $i18n.locale == ''" src="https://alkafeel.net/uploads/nup1.jpg"></v-img>
                    <!-- <v-img v-else :src="'/assets/img/lup1'+$i18n.locale+'.jpg'"></v-img> -->
                </a>
            </v-responsive>
            <v-responsive class="d-flex d-sm-none"  style="    BACKGROUND-SIZE: 100% 200px !important">
                <a :href="'https://alkafeel.net/news/index?id=28822&lang='+$i18n.locale" target="_blank" style="    BACKGROUND-SIZE: 100% 200px !important">
                    <v-img v-if="$i18n.locale == 'ar' || $i18n.locale == ''" src="https://alkafeel.net/uploads/nup3.jpg"  style="BACKGROUND-SIZE: 100% 200px !important"></v-img>
                    <!-- <v-img v-else :src="'/assets/img/lup3'+$i18n.locale+'.jpg'"  style="BACKGROUND-SIZE: 100% 200px !important"></v-img> -->
                </a>
            </v-responsive>
        </template>

            <div class="panl-title">
                <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" />
                <router-link :to="'/articles?lang='+$i18n.locale" style="text-decoration: none;">

                    <div class="panl-title-text d-inline tl"> {{ $t("news") }}</div>
                </router-link>


            </div>




            <v-layout row wrap style="padding-top:10px">

                <v-flex md6 xs12 class="home_main_new" v-if="loadingNews">

                    <v-skeleton-loader class="mx-auto" max-width="100%" max-height="500" type="card-avatar, article">
                    </v-skeleton-loader>

                </v-flex>
                <v-flex v-else md6 xs12 class="home_main_new">
                    <div style="width: 100%;" v-for="item in impoNew" :key="item">


                        <!-- <a :href="'https://alkafeel.net/news/index?id='+item.id+'&lang='+$i18n.locale" style="text-decoration: none !important;"> -->
                        <router-link :to="'/new/'+item.id+'?lang='+$i18n.locale" style="text-decoration: none;">
                            <div class="home_main_new_img">
                                <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+item.image"
                                    :src="'https://alkafeel.net/news/mcroped96/625/'+item.image"
                                    style="height:100%;width:100%;border-radius:4px;">
                                </v-img>
                                <div class="home_main_new_cat">
                                    <!-- {{moment.unix(item.time).locale($i18n.locale).fromNow()}} -->
                                    {{TimeSince(item.time)}}
                                </div>
                            </div>
                            <div class="home_main_new_title tl" v-html="item.title">

                            </div>
                            <div class="home_main_new_des "
                                v-html="stripHTML($options.filters.subStrArtTitleMaindesc(item.text))">


                            </div>

                        </router-link>
                        <!-- </a> -->

                    </div>

                </v-flex>


                <v-flex v-if="loadingNews" xs12 md6 class="home_main_sec hidden-sm-and-down"
                    style="padding-top: 23px; ">


                    <v-skeleton-loader v-for="item in news_skeleton.slice(1,2)" :key="item" class="mx-auto"
                        max-width="100%" max-height="152" type="card-avatar, article"></v-skeleton-loader>

                    <v-skeleton-loader v-for="item in news_skeleton.slice(2,5)" style="padding-top: 23px; " :key="item"
                        class="mx-auto" max-width="100%" max-height="152" type="card-avatar, article">
                    </v-skeleton-loader>




                </v-flex>


                <v-flex v-else xs12 md6 class="home_main_sec hidden-sm-and-down " style="padding-top: 23px; ">
                    <v-layout row wrap v-for="item in news.slice(0,5)" :key="item"
                        style="height: 152px;padding-right:15px;padding-left:11px" class="d-none d-sm-flex">


                        <v-flex md8>
                            <!-- <a :href="'https://alkafeel.net/news/index?id='+item.id+'&lang='+$i18n.locale" style="text-decoration: none !important; color: #00112C;"> -->
                            <router-link :to="'/new/'+item.id+'?lang='+$i18n.locale"
                                style="text-decoration: none;color: #00112C;">
                                <div class="home_second_new_title tl" v-html="$options.filters.subStrArtTitleMain(item.title)" v-if="$i18n.locale == 'ar' || $i18n.locale == 'pr'">
                                </div>

                                <div class="home_second_new_title tl" v-html="item.title" v-else>


                                </div>

                                <div class="home_second_new_desc" v-html="$options.filters.subStrArtTitle(item.text)"
                                    v-if="$i18n.locale == 'ar' || $i18n.locale == 'pr'">




                                </div>
                            </router-link>
                            <!-- </a> -->
                        </v-flex>


                        <v-flex md4 style="height:120px" class="new_pro_ipd_manin">
                            <!-- <a :href="'https://alkafeel.net/news/index?id='+item.id+'&lang='+$i18n.locale" style="text-decoration: none !important; color: #00112C;"> -->
                            <router-link :to="'/new/'+item.id+'?lang='+$i18n.locale"
                                style="text-decoration: none;color: #00112C;">

                                <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+item.image"
                                    :src="'https://alkafeel.net/news/mcroped96/625/'+item.image"
                                    style="border-radius:4px;;width:100%;height:100%;"></v-img>
                                <div class="home_main_new_cat" style="bottom:26px !important">
                                    <!-- {{moment.unix(item.time).locale($i18n.locale).fromNow()}} -->


                                    {{TimeSince(item.time)}}
                                </div>

                            </router-link>
                            <!-- </a> -->
                        </v-flex>

                        <v-flex>

                        </v-flex>

                    </v-layout>





                </v-flex>




                <swiper style="-webkit-transform-style: preserve-3d; -webkit-backface-visibility: hidden;"
                    class="hidden-md-and-up swiper  " :options="swiperOptionss">

                    <template v-if="loadingNews">
                        <swiper-slide v-for="item in news_skeleton.slice(1,7)" :key="item"
                            style="-webkit-backface-visibility:hidden;padding: 13px;-webkit-transform-style: preserve-3d;">
                            <v-card flat style="width: 100%;" max-height="500">
                                <v-skeleton-loader class="mx-auto" max-width="100%" max-height="500" type="card-avatar">
                                </v-skeleton-loader>
                            </v-card>
                        </swiper-slide>
                    </template>


                    <template v-else>
                        <swiper-slide v-for="item in news.slice(0,7)" :key="item"
                            style="-webkit-backface-visibility:hidden;padding: 13px;-webkit-transform-style: preserve-3d; ">
                            <v-card flat style="width: 100%;">


                                <!-- <a :href="'https://alkafeel.net/news/index?id='+item.id+'&lang='+$i18n.locale" style="text-decoration: none !important;"> -->
                                <router-link :to="'/new/'+item.id+'?lang='+$i18n.locale" style="text-decoration: none;">
                                    <div class="home_main_new_img" style="height:250px">
                                        <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+item.image"
                                            :src="'https://alkafeel.net/news/mcroped96/625/'+item.image"
                                            style="height:100%;width:100%;border-radius:4px;">
                                        </v-img>
                                        <div class="home_main_new_cat" style="bottom: 34px !important;">

                                            <!-- {{moment.unix(item.time).locale($i18n.locale).fromNow()}} -->

                                            {{TimeSince(item.time)}}
                                        </div>
                                        <!-- <div class="home_main_new_cat">
                                            قسم النشاطات
                                        </div> -->
                                    </div>
                                    <div class="home_main_new_title tl"
                                        v-html="$options.filters.subStrArtTitlesSec(item.title)"
                                        style="padding-top: 5px;    font-size: 17px;">

                                    </div>
                                </router-link>
                                <!-- </a> -->

                            </v-card>
                        </swiper-slide>
                    </template>



                </swiper>


            </v-layout>

            <br>
        <div>
        <!-- <v-responsive class="d-none d-sm-flex">

            <a href="https://form.jotform.com/arbaeenfestival/RaeitArbaeen" target="_blank">
            <v-img :src="'/assets/img/alkafeelTv.jpeg'"></v-img>
        </a>


        


        </v-responsive>
        <v-responsive class="d-flex d-sm-none">
        <a href="https://form.jotform.com/arbaeenfestival/RaeitArbaeen" target="_blank">
            <v-img :src="'/assets/img/alkafeelTv_phone.jpeg'"></v-img>
        </a>
        </v-responsive> -->
  </div>
       

        </v-container>

   

       
        
    </div>
</template>


<style scoped>

.ccv .v-image__image--cover {
            BACKGROUND-SIZE: 100% 200px !important
        }
    .ico {
        height: 55px;
        stroke: #FFF;
        transition: .3s ease all;
    }

    .m-link:hover .ico {
        stroke: #B1BD52 !important;
    }

    .ico2 {
        height: 55px;
        fill: #FFF;
        transition: .3s ease all;
    }

    .m-link:hover .ico2 {
        fill: #B1BD52 !important;
    }

    .m-link:hover .ico-text {
        color: #B1BD52 !important;
        transition: .3s ease all;
    }

    @media (min-width: 320px) and (max-width: 766px) {

        .muhram {
            background-image: url('/assets/img/1-1.jpg') !important;
            height: 600px !important;
            flex-wrap: wrap;
        }

        .m-link {
            width: 50%;
        }

        .i-muhram {
            flex-wrap: wrap;
            height: 350px;
        }

    }

    .i-muhram {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    .v-application a {
        text-decoration: none !important;
    }

    .muhram {
        background-image: url('/assets/img/moharam/1.jpg');
        width: 100%;
        height: 300px;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        padding-bottom: 24px;
        justify-content: space-around;
        text-align: center;
        background-position-x: center;
        flex-direction: column;
        align-items: center;
        border-radius: 4px;
    }

    .d-icon {
        display: flex;
        text-align: center;
    }

    .text-white {
        color: #fff;
    }


   
    .text-center {
        text-align: center;
    }

    @media only screen and (min-width: 321px) {

        /* Styles */
        .vvfed {
            display: block !important;
        }
    }

    .home_main_new {
        width: 100%;
        /* padding-left: 30px ; */
    }

    @media screen and (min-width:320px) {
        .home_main_new {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @media screen and (min-width: 992px) {

        /* .home_main_new{
width: 91%;
 } */
        .home_main_new_img {
            /* width: 570px; */
            height: 382px;
        }
    }
</style>
<script>
    export default {
        inject: {
            theme: {
                default: {
                    isDark: false
                },
            },
        },

        filters: {


            FormatDate: function (unixTime) {
                let d = new Date(unixTime * 1000);
                let ye = new Intl.DateTimeFormat('en', {
                    year: 'numeric'
                }).format(d);
                let mo = new Intl.DateTimeFormat('en', {
                    month: '2-digit'
                }).format(d);
                let da = new Intl.DateTimeFormat('en', {
                    day: '2-digit'
                }).format(d);
                return (`${da}-${mo}-${ye}`);
            },
            subStrArtTitlesSec: function (string) {

                if (string != null) {
                    return string.substring(0, 75) + ' ... ';
                }

            },

            subStrArtTitleMain: function (string) {

                if (string != null) {
                    return string.substring(0, 200) + ' ... ';
                }

            },

            subStrArtTitleMaindesc: function (string) {

                if (string != null) {
                    return string.replace(/\[\/?[^\]]*\]/g, '').substring(0, 900) + ' ...';
                }

            },

            subStrArtTitle: function (string) {

                if (string != null) {
                    return string.replace(/\[\/?[^\]]*\]/g, '').substring(0, 110) + ' ... ';
                }

            },


        },
        computed: {

        },
        methods: {
            TimeSince: function (date) {
                date = date * 1000;
                var seconds = Math.floor((new Date() - date) / 1000);

                var interval = seconds / 31536000;

                if (interval > 1) {
                    return this.$t("time_before") + ' ' + Math.floor(interval) + ' ' + this.$t("time_year");
                }

                interval = seconds / 2592000;
                if (interval > 1) {
                    return this.$t("time_before") + ' ' + Math.floor(interval) + ' ' + this.$t("time_month");
                }
                interval = seconds / 86400;
                if (interval > 1) {
                    return this.$t("time_before") + ' ' + Math.floor(interval) + ' ' + this.$t("one_day");


                }
                interval = seconds / 3600;
                if (interval > 1) {
                    return this.$t("time_before") + ' ' + Math.floor(interval) + ' ' + this.$t("time_hour");
                }
                interval = seconds / 60;
                if (interval > 1) {
                    return this.$t("time_before") + ' ' + Math.floor(interval) + ' ' + this.$t("time_min");
                }
                return this.$t("time_before") + ' ' + Math.floor(seconds) + ' ' + this.$t("time_sec");


            },
            getNews() {

                this.$axios.get("articles/GetByLimitHome/4", {})
                    .then(res => {
                        //this.loading = false;
                        this.loadingNews = false;
                        this.news = res.data;


                    })
                    .catch(() => {
                        //this.loading = false;
                    });

            },
            prev() {
                this.$refs.switchDemo.prev()
            },
            next() {
                this.$refs.switchDemo.next()
            },
            grtimpoNews() {
                this.$axios.get("articles/GetImportant/1", {})
                    .then(res => {
                        this.impoNew = res.data;

                    })
                    .catch(() => {});
            },
            stripHTML(value) {
                const div = document.createElement('div')
                div.innerHTML = value
                const text = div.textContent || div.innerText || ''
                return text
            },
        },


        created() {

            this.$axios.get("timestamp", {}).then(res => {this.timestamp = res.data;})
            this.getNews();
            this.grtimpoNews();
            var dir;

            let params = (new URL(document.location)).searchParams;
            var lang = params.get("lang");
            if (lang == 'en' || lang == 'de' || lang == 'fr' || lang == 'sw' || lang == 'tr') {
                dir = 'ltr';
            } else {
                dir = 'rtl';
            }

            this.$store.dispatch("UpdateLang", {
                lang: lang,
                dir: dir
            });


        },
        data() {
            return {
                news: [],
                impoNew: [],
                logo: 'logo6.svg',
                timestamp: 0,
                loadingNews: true,
                min: this.$t("time_min"),
                //   moment: moment,
                news_skeleton: [{
                        id: 1
                    },
                    {
                        id: 1
                    },
                    {
                        id: 1
                    },
                    {
                        id: 1
                    },
                    {
                        id: 1
                    },
                    {
                        id: 1
                    },
                    {
                        id: 1
                    },
                    {
                        id: 1
                    },
                    {
                        id: 1
                    },
                ],
                swiperOptionss: {

                    loop: false,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true

                    },


                    //      mousewheel: true,


                    slidesPerView: 1,
                    freeMode: false,
                    keyboard: true,
                    breakpoints: {

                        640: {
                            slidesPerView: 1,
                            width: 320,


                        },
                        // 360: {
                        //     slidesPerView: 1,
                        //     spaceBetween: 10,
                        //     width: 320,


                        // },
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 1,
                            width: 289,


                        },

                        768: {
                            slidesPerView: 1,
                            width: 320,


                        },
                        1024: {
                            slidesPerView: 1,
                            width: 320,
                            // spaceBetween: 23,

                        },
                    }



                },
                // news:[
                //     {
                //          text: '  بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراع',
                // des: ' بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراع'

                //     },
                //      {
                //          text: '  بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراع',
                // des: ' بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراع'

                //     },
                //      {
                //          text: '  بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراع',
                // des: ' بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراع'

                //     },
                //      {
                //          text: '  بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراع',
                // des: ' بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراع'

                //     },

                // ],
                text: ' بعد أن اختتمتْ مشاركتها في فعّاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراعيّة والحيوانيّ',
                des: '     الدوليّ للمنتجات الزراعيّة والحيوانيّة، التي وُصفت بالناجحة والفعّالة بعد أن اختتمتْ مشاركتها في فعاليات معرض ومؤتمر أربيل الدوليّ للمنتجات الزراعيّة والحيوانيّة، التي وُصفت بالناجحة والفعّال'
            }
        },
    }
</script>